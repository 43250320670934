@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-img{
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(0,0,0,0.19091386554621848) 100%);
}

input[type="checkbox"] {
  accent-color: black;    
}

.repository{
  width: 454px;
  min-height: 264px;
}

.marginLeftNavbar{
  margin-left: 284px;
}

@media screen and (max-width: 740px) {
  .repository{
    width: 294px !important;
  }
  .block-flex{
    display: flex !important;
  }
  .flex-block{
    display: block !important;
  }
  .padding-right{
    padding: 4px;
  }
  .text{
    font-size: 36px !important;
    line-height: 40px !important;
    margin-top: 154px;
  }
  .rightMenu{
    right: 3px !important;
  }
  .marginLeftNavbar{
    margin-left: 84px;
  }
}

.block-flex{
  display: block;
}

.flex-block{
  display: flex;
}

.loading-screen {
  box-shadow: 0 25px 150px 60px #e3e3e3;
  background-color: #e3e3e3;
  opacity: 40%;
  width: 100px;
  height: 100%;
  position: relative;
  animation: moveAnimation 0.8s infinite linear;
}

@keyframes moveAnimation {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - 30px);
  }
}

::-webkit-scrollbar{
  background-color: transparent;
  width: 8px;
}

::-webkit-scrollbar-thumb{
  background-color: gray;
  border-radius: 24px;
}

body{
  overflow-x: hidden;
  background-color: #f9f8fd
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.text{
  font-size: 48px;
  line-height: 54px;
}

.transformLeft{
  transition: margin-left 0.3s ease;
}

body{
  background-image: url("./images/bg-background.png");
  background-size: cover; /* Adjust how the image covers the entire background */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-attachment: fixed;
}

.rightMenu{
  right: 40px;
}